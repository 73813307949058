<template>
  <div>
    <iframe :src="pdfViewerUrl" width="100%" height="900px" style="border: none"></iframe>
    <!-- フォールバックとしてPDFダウンロードリンク -->
    <p v-if="!isPdfSupported" style="margin-top: 30px">
      SukiSukiらいぶすてーしょんの資料は
      <a :href="pdfUrl" target="_blank" rel="noopener noreferrer">こちら</a
      >からダウンロードしてください。
    </p>
  </div>
</template>

<script setup>
import { ref } from "vue";

// PDFファイルのURL
const pdfUrl = ref(
  "https://firebasestorage.googleapis.com/v0/b/rf-prd-sukisuki-ls.appspot.com/o/live_station_images_common%2F%E3%83%AA%E3%82%A2%E3%83%AB%E3%82%BF%E3%82%A4%E3%83%A0%E3%81%8F%E3%81%97%E3%82%99%E5%BC%95%E3%81%8D%E3%80%8CSukiSuki%E3%82%89%E3%81%84%E3%81%B5%E3%82%99%E3%81%99%E3%81%A6%E3%83%BC%E3%81%97%E3%82%87%E3%82%93%E3%80%8D%E3%81%93%E3%82%99%E6%8F%90%E6%A1%88%E8%B3%87%E6%96%99.pdf?alt=media&token=c659fa97-9ff2-45df-9d87-53c1063fc905"
);

// PDFビューアー用のURL
const pdfViewerUrl = ref(
  `https://docs.google.com/gview?url=${encodeURIComponent(pdfUrl.value)}&embedded=true`
);

// ブラウザがネイティブのPDF表示をサポートしているか確認
const isPdfSupported = ref(
  (() => {
    const canvas = document.createElement("canvas");
    return !!(canvas.getContext && canvas.getContext("2d"));
  })()
);
</script>
