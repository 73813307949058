<script setup>
import { ref, watch, toRefs } from "vue";
import db from "/src/main.js"; //追加
import { collection, getDocs } from "firebase/firestore";
import { useRoute } from "vue-router";
const props = defineProps({
  rankingarray: Array,
  // rankingGoodsData: Array,
});
const route = useRoute();
const event_id = route.query.event_id;
// const emit = defineEmits(["cardclose"]);

// const cardclose = () => {
//   emit("cardclose");
// };

const { rankingarray } = toRefs(props);
const showrankingarray = ref();
showrankingarray.value = rankingarray.value;

watch(rankingarray, () => {
  showrankingarray.value = rankingarray.value;
});

//当該ベンダーのランキング用グッズの取得 本来は親に書くべき
const rankingGoodsData = ref([]);
const firstProductpic = ref();
const firstProductname = ref();
const firstProductmaterial = ref();
const firstProductsize = ref();
const secondProductpic = ref();
const secondProductname = ref();
const secondProductmaterial = ref();
const secondProductsize = ref();
const thirdProductpic = ref();
const thirdProductname = ref();
const thirdProductmaterial = ref();
const thirdProductsize = ref();
const getRankingProductsData = async () => {
  const querySnapshot = await getDocs(
    collection(db, "Goods_products", event_id, "rankingproducts")
  );
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    rankingGoodsData.value.push(data);
  });
  firstProductpic.value = rankingGoodsData.value[0].rankingproduct_image;
  firstProductname.value = rankingGoodsData.value[0].rankingroduct_name;
  firstProductmaterial.value = rankingGoodsData.value[0].rankingroduct_material;
  firstProductsize.value = rankingGoodsData.value[0].rankingroduct_size;
  secondProductpic.value = rankingGoodsData.value[1].rankingproduct_image;
  secondProductname.value = rankingGoodsData.value[1].rankingroduct_name;
  secondProductmaterial.value = rankingGoodsData.value[1].rankingroduct_material;
  secondProductsize.value = rankingGoodsData.value[1].rankingroduct_size;
  thirdProductpic.value = rankingGoodsData.value[2].rankingproduct_image;
  thirdProductname.value = rankingGoodsData.value[2].rankingroduct_name;
  thirdProductmaterial.value = rankingGoodsData.value[2].rankingroduct_material;
  thirdProductsize.value = rankingGoodsData.value[2].rankingroduct_size;
};

getRankingProductsData();
const selectedProduct = ref();

console.log(selectedProduct.value);
// const secondPlaceProduct = computed(() => {
//   return rankingGoodsData.value.find((item) => item.rankingproduct_id === "2");
// });

// const thirdPlaceProduct = computed(() => {
//   return rankingGoodsData.value.find((item) => item.rankingproduct_id === "3");
// });

// 商品モーダル
const isModalOpen = ref(false);
const selectedProductPic = ref("");
const selectedProductName = ref("");
const selectedProductMaterial = ref("");
const selectedProductSize = ref("");
// const selectedProduct = ref({});
const openProductModal = (productPic, name, material, size) => {
  console.log(productPic);
  console.log(name);
  isModalOpen.value = true;
  selectedProductPic.value = productPic;
  selectedProductName.value = name;
  selectedProductMaterial.value = material;
  selectedProductSize.value = size;
};
const closeModal = () => {
  isModalOpen.value = false;
};
</script>
<template>
  <!-- <div> -->
  <p class="subdis">ランキング上位の視聴者様には<br />以下の特別グッズをプレゼントいたします</p>

  <div class="inner">
    <div class="titleBoxSsr">
      <span class="main">1位</span>
    </div>
    <div class="productcontainer">
      <div class="subproductcontaine">
        <img
          class="productpic"
          :src="firstProductpic"
          @click="
            openProductModal(
              firstProductpic,
              firstProductname,
              firstProductmaterial,
              firstProductsize
            )
          "
        />
        <div class="productname">
          {{ firstProductname }}
        </div>
      </div>
    </div>
    <p>AND</p>
    <div class="productcontainer">
      <div class="subproductcontaine">
        <img
          class="productpic"
          :src="secondProductpic"
          @click="
            openProductModal(
              secondProductpic,
              secondProductname,
              secondProductmaterial,
              secondProductsize
            )
          "
        />
        <div class="productname">
          {{ secondProductname }}
        </div>
      </div>
    </div>
    <p>AND</p>
    <div class="productcontainer">
      <div class="subproductcontaine">
        <img
          class="productpic"
          :src="thirdProductpic"
          @click="
            openProductModal(
              thirdProductpic,
              thirdProductname,
              thirdProductmaterial,
              thirdProductsize
            )
          "
        />
        <div class="productname">
          {{ thirdProductname }}
        </div>
      </div>
    </div>

    <div class="titleBoxSsr">
      <span class="main">2位</span>
    </div>
    <div class="productcontainer">
      <div class="subproductcontaine">
        <img
          class="productpic"
          :src="secondProductpic"
          @click="
            openProductModal(
              secondProductpic,
              secondProductname,
              secondProductmaterial,
              secondProductsize
            )
          "
        />
        <div class="productname">
          {{ secondProductname }}
        </div>
      </div>
    </div>
    <p>AND</p>
    <div class="productcontainer">
      <div class="subproductcontaine">
        <img
          class="productpic"
          :src="thirdProductpic"
          @click="
            openProductModal(
              thirdProductpic,
              thirdProductname,
              thirdProductmaterial,
              thirdProductsize
            )
          "
        />
        <div class="productname">
          {{ thirdProductname }}
        </div>
      </div>
    </div>

    <div class="titleBoxSsr">
      <span class="main">3位</span>
    </div>
    <div class="productcontainer">
      <div class="subproductcontaine">
        <img
          class="productpic"
          :src="thirdProductpic"
          @click="
            openProductModal(
              thirdProductpic,
              thirdProductname,
              thirdProductmaterial,
              thirdProductsize
            )
          "
        />
        <div class="productname">
          {{ thirdProductname }}
        </div>
      </div>
    </div>

    <h4 class="subtitle mt-10">現在のランクング順位</h4>
    <div class="inner">
      <div class="body">
        <ul>
          <template v-for="(item, index) in showrankingarray" :key="item">
            <li class="ml-10 mr-10">
              <div class="icon_conteiner">
                <div class="icon"><img :src="item.iconURL" /></div>
                <div v-if="index == 0" class="rank"><img src="img/rank1.png" /></div>
                <div v-else-if="index == 1" class="rank"><img src="img/rank2.png" /></div>
                <div v-else-if="index == 2" class="rank"><img src="img/rank3.png" /></div>
              </div>
              <div class="name">{{ item.name }}</div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
  <!-- モーダルウィンドウ -->
  <div v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <img :src="selectedProductPic" class="modal-image" />
      <div class="modal-productname">{{ selectedProductName }}</div>
      <div class="modal-productsize">サイズ：{{ selectedProductSize }}</div>
      <div class="modal-productsize">素材：{{ selectedProductMaterial }}</div>
    </div>
  </div>
</template>

<style scoped>
ul {
}
ul li {
  display: flex;
  border-bottom: solid 1px #ccc;
  margin-bottom: 15px;
}
.icon_conteiner {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.name {
  margin-top: 15px;
}
ul li .icon {
  border-radius: 100px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
ul li .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ul li .rank {
  position: absolute;
  display: inline-block;
  top: 27px;
  right: 0px;
  width: 15px;
  height: 20px;
}
ul li .rank img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.subtitle {
  margin-top: 15px;
  margin-bottom: 30px;
}

.titleBoxSsr {
  background-color: rgb(242, 242, 242);
  padding: 10px 10px 10px 10px;
}
.titleBoxSsr {
  /* background: rgb(248, 245, 227); */
  /* background: rgb(242, 242, 242); */
  background: rgb(152, 45, 166);
  padding: 10px 10px 10px 30px;
  margin: 0 10px 10px 10px;
  position: relative;
  color: rgb(105, 105, 105);
}
.titleBoxSsr .image {
  display: inline-block;
  width: 40px;
  margin-right: 10px;
  position: absolute;
  top: -20px;
  left: -20px;
}
.titleBoxSsr .image img {
  height: 170%;
  width: 170%;
  margin-right: 0px;
}
.titleBoxSsr .main {
  font-size: 1.2rem;
  /* margin-right: 60px; */
  font-weight: bold;
  color: white;
}
.titleBoxSsr .sub {
  font-size: 0.9rem;
  position: absolute;
  right: 10px;
}
.titleBoxSsr .main {
  font-size: 1.2rem;
  margin-right: 40px;
  font-weight: bold;
}
.subdis {
  margin-bottom: 25px;
}
.productcontainer {
  display: flex;
  justify-content: center; /* 中央揃えのために追加 */
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.subproductcontaine {
  border: 1px solid rgb(216, 216, 216);
  padding: 10px;
  margin: 10px 1.5% 10px 1.5%;
  width: 30%;
  text-align: center;
  border-radius: 8px;
  display: flex; /* 子要素を中央揃えするために追加 */
  flex-direction: column; /* 子要素を垂直に並べるために追加 */
  align-items: center; /* 子要素を水平方向に中央揃えするために追加 */
}

.productpic {
  width: 100%;
  margin-bottom: 0.5em;
}

.productname {
  font-size: 0.8rem;
  text-align: center; /* 文字を中央揃えにするために追加 */
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 2000px;
  text-align: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-image {
  width: 100%;
  max-width: 700px;
  margin-bottom: 20px;
}

.modal-productname {
  font-size: 1.2rem;
  font-weight: bold;
}
.modal-productsize {
  font-size: 1rem;
  margin-top: 5px;
}
/* モバイル用のスタイル */
@media (max-width: 1000px) {
  .modal-content {
    width: 90%;
    max-width: 90%;
    padding: 10px;
    margin-top: 50%; /* 上からの余白を追加 */
  }
  .modal-image {
    max-width: 100%;
  }
}
</style>
