<script setup>
import { ref } from "vue";
import MainViewOrganism from "/src/components/organisms/MainViewOrganism.vue";
import { stripe } from "/src/stripe.js";
import { useRoute, useRouter } from "vue-router";
import db from "../main.js"; //追加
import {
  doc,
  setDoc,
  onSnapshot,
  query,
  collection,
  getDocs,
  orderBy,
  limit,
  getDoc,
  where,
  Timestamp,
  // start_at,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

const props = defineProps({
  user: String,
});
const router = useRouter();
const showmessage = ref([]);
const showranking = ref();

const route = useRoute();
const event_id = ref(route.query.event_id);
const isLoadingforcredit = ref(true);
//メッセージを開いた時刻
let siteOpenTime = new Date(); // JavaScriptのDateオブジェクト

// Timestampオブジェクトに変換
let openMessageTime = Timestamp.fromDate(siteOpenTime);

const q = query(
  collection(db, "Goods_automessages", event_id.value, "automessages"),
  orderBy("created_at", "asc"),
  where("created_at", ">=", openMessageTime) //where条件を追加
);

onSnapshot(q, (querySnapshot) => {
  const nameshot = [];
  querySnapshot.docChanges().forEach((change) => {
    if (change.type === "added") {
      const setmessage = change.doc.data();
      showmessage.value.push(setmessage);
    }
    if (change.type === "modified") {
      console.log("変更");
    }
    if (change.type === "removed") {
      console.log("削除");
    }
  });
  console.log(nameshot);
  // showmessage.value = nameshot;
  // console.log(showmessage.value);
});

//videoのURL用及びお茶代
const sendmessage = ref("testsendmessage");
const videourl = ref(null);
const ochaPrice = ref(0);
const getvideurl = async () => {
  onSnapshot(doc(db, "Goods_products", event_id.value), (doc) => {
    videourl.value =
      process.env.VUE_APP_CLOUDFLARE_SUBDOMAIN +
      doc.data().videouid +
      "/manifest/video.m3u8?protocol=llhls";
    ochaPrice.value = doc.data().ocha_price;
  });
};
getvideurl();

const addresses = ref([]);

async function getAddressData() {
  addresses.value = [];
  const querySnapshot = await getDocs(collection(db, "users", props.user.uid, "addresses"));
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    addresses.value.push(data);
  });
}
getAddressData();
//登録済みクレジットカード呼び出し
const cardsInfo = ref(null);
const defaultCard = ref(null);
async function getCreditCard() {
  isLoadingforcredit.value = true;
  const functions = getFunctions();
  functions.region = "asia-northeast1";

  const myCallableFunction = httpsCallable(functions, "Common_cardslist");
  myCallableFunction({})
    .then((result) => {
      cardsInfo.value = result.data.cards;
      defaultCard.value = result.data.defaultCardId;

      isLoadingforcredit.value = false;
    })
    .catch((error) => {
      console.log(error.message);
    });
}
// getCreditCard();

const errorfortreca = ref(null); // エラー情報を保持するためのref
//トレカ購入（クレジットカード）
const supercommentDone = ref(false);
const comfirmBuy = async (selectitem, choosebuycard, comments, paymentMethodId, trecaid) => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  //refを消す
  //価格
  const choosebuycardNo = choosebuycard.value;
  const createPaymentIntent = httpsCallable(functions, "Goods_TrecaCreatePaymentIntent");

  try {
    const result = await createPaymentIntent({
      paymentMethodId: paymentMethodId.value,
      choosebuycard: choosebuycardNo,
    });
    const clientSecret = result.data.clientSecret; // 修正ポイント
    const paymentIntentId = result.data.paymentIntentId;
    // オーソリが成功

    //    ここで次のステップに進む

    try {
      // クライアントで3Dセキュア認証を実行
      const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId.value,
      });

      if (confirmError) {
        console.error("3Dセキュア認証エラー:", confirmError.message);
        errorfortreca.value = confirmError;
        return;
      }

      if (paymentIntent.status !== "requires_capture") {
        console.error("キャプチャ可能な状態ではありません:", paymentIntent.status);
        error.value = "hapen error";
        errorfortreca.value = "hapen error"; // エラー情報をrefにセット
        // error.value = error.message;
        return;
      }
      //refを消す
      // const cardIdNo = cardId.value;
      // const choosebuycardNo = choosebuycard.value;

      // const commentsNo = comments.value;
      // console.log("コメント" + commentsNo);
      // const chooseaddressNo = chooseaddress.value;
      const event_idNo = event_id.value;
      const selectitemNo = selectitem.value;
      const trecaidNo = trecaid.value;

      // サーバーでキャプチャ処理を実行とスーパーコメント情報送付
      const captureTrecaPayment = httpsCallable(functions, "Goods_TrecaCapturePayment");
      const result = await captureTrecaPayment({
        // selectnumber: selectnumberNo,
        choosebuycard: choosebuycardNo,
        paymentIntentId: paymentIntentId,
        // buyfromlive: buyfromlive,
        paymentMethodId: paymentMethodId.value,
        // card_id: cardIdNo,
        comments: comments.value,
        event_id: event_idNo,
        orderItems: selectitemNo,
        trecaid: trecaidNo,
      });

      if (result.data.status === "succeeded") {
        console.log(result);
        supercommentDone.value = true;
        // console.log("aaaaaooo" + result.data.stripeOrderId);
      } else {
        alert("支払いキャプチャーに失敗しました: " + result.data.status);
        errorfortreca.value = "hapen error"; // エラー情報をrefにセット
      }
    } catch (err) {
      console.error("キャプチャー中にエラーが発生しました:", err.message);
      errorfortreca.value = err.message; // エラー情報をrefにセット
    }
  } catch (err) {
    console.error("Error during authorization:", err.message);
    errorfortreca.value = err.message; // エラー情報をrefにセット
  }
};

//   const functions = getFunctions();
//   functions.region = "asia-northeast1";
//   //refを消す
//   const cardIdNo = cardId.value;
//   const choosebuycardNo = choosebuycard.value;
//   const commentsNo = comments.value;
//   // const chooseaddressNo = chooseaddress.value;
//   const event_idNo = event_id.value;
//   const selectitemNo = selectitem.value;
//   const trecaidNo = trecaid.value;
//   const myCallableFunction = httpsCallable(functions, "Goods_TrecaCharge");
//   myCallableFunction({
//     card_id: cardIdNo,
//     choosebuycard: choosebuycardNo,
//     comments: commentsNo,
//     event_id: event_idNo,
//     orderItems: selectitemNo,
//     trecaid: trecaidNo,
//   })
//     .then((result) => {
//       console.log(result);
//       supercommentDone.value = true;
//     })
//     .catch((err) => {
//       console.log(err.message); // エラーメッセージをコンソールに出力
//       errorfortreca.value = err; // エラー情報をrefにセット
//     });
// };

//トレカ購入（キャリア決裁）
const comfirmBuyforcarrierpayment = (
  selectitem,
  choosebuycard,
  comments,
  chooseaddress,
  cardId,
  trecaid
) => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  //refを消す

  const choosebuycardNo = choosebuycard.value;
  const commentsNo = comments.value;
  const chooseaddressNo = chooseaddress.value;
  const event_idNo = event_id.value;
  const selectitemNo = selectitem.value;
  const trecaidNo = trecaid.value;
  const myCallableFunction = httpsCallable(functions, "Goods_TrecaChargeforcarrierpayment");
  myCallableFunction({
    choosebuycard: choosebuycardNo,
    comments: commentsNo,
    chooseaddress: chooseaddressNo,
    event_id: event_idNo,
    orderItems: selectitemNo,
    trecaid: trecaidNo,
  })
    .then((result) => {
      window.location.href = decodeURIComponent(result.data.redirectUrl);
    })
    .catch((error) => {
      console.log(error.message);
      error.value = error.message;
    });
};

const chooseGoods = async (selectnumber) => {
  if (props.user) {
    //カートへ書き込み
    await setDoc(
      doc(db, "users", props.user.uid, "Goods_productcartitems", "productcartitems"),
      {
        quantity: selectnumber.value,
        event_id: event_id.value,
        vendorname_ja: vendorname_ja.value,
      },
      {
        merge: false,
      }
    );
  }
};

//トレカの種類を書き込み
const chooseTreca = async () => {
  if (props.user) {
    //カートへ書き込み
    await setDoc(
      doc(db, "users", props.user.uid, "Goods_supercommentcartitems", "supercommentcartitems"),
      {
        // quantity: selectnumber.value,
        event_id: event_id.value,
        vendorname_ja: vendorname_ja.value,
      },
      {
        merge: false,
      }
    );
  }
};

//商品のグッズガチャ購入(クレジットカード)レガシー
// const comfirmGoodsbefore = (selectnumber, choosebuycard, chooseaddress, cardId) => {
//   const functions = getFunctions();
//   functions.region = "asia-northeast1";
//   //refを消す
//   const selectnumberNo = selectnumber.value;
//   const choosebuycardNo = choosebuycard.value;
//   const chooseaddressNo = chooseaddress.value;
//   const event_idNo = event_id.value;
//   const cardIdNo = cardId.value;
//   //配信中の購入かどうか
//   const buyfromlive = true;
//   const myCallableFunction = httpsCallable(functions, "Goods_GoodsCharge");
//   myCallableFunction({
//     selectnumber: selectnumberNo,
//     choosebuycard: choosebuycardNo,
//     chooseaddress: chooseaddressNo,
//     event_id: event_idNo,
//     card_id: cardIdNo,
//     shippingFee: shippingFee.value,
//     buyfromlive: buyfromlive,
//   })
//     .then((result) => {
//       orderId.value = result.data.stripeOrderId;
//       console.log("aaaaaooo" + result.data.stripeOrderId);
//     })
//     .catch((err) => {
//       console.log(err.message); // エラーメッセージをコンソールに出力
//       error.value = err; // エラー情報をrefにセット
//     });
// };
const error = ref(null); // エラー情報を保持するためのref
//商品のグッズガチャ購入(クレジットカード)selectnumber, choosebuycard, chooseaddress, paymentMethodId
const orderId = ref();
const comfirmGoods = async (selectnumber, choosebuycard, chooseaddress, paymentMethodId) => {
  const functions = getFunctions();
  const createPaymentIntent = httpsCallable(functions, "Goods_CreatePaymentIntent");

  try {
    const result = await createPaymentIntent({
      paymentMethodId,
    });
    const clientSecret = result.data.clientSecret; // 修正ポイント
    const paymentIntentId = result.data.paymentIntentId;
    // オーソリが成功

    //    ここで次のステップに進む

    try {
      // クライアントで3Dセキュア認証を実行
      const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId,
        setup_future_usage: "on_session", // サービス内でユーザーが即時購入する場合
      });

      if (confirmError) {
        console.error("3Dセキュア認証エラー:", confirmError.message);
        error.value = confirmError;
        return;
      }

      if (paymentIntent.status !== "requires_capture") {
        console.error("キャプチャ可能な状態ではありません:", paymentIntent.status);
        error.value = "hapen error";
        // error.value = error.message;
        return;
      }

      //refを消す
      const selectnumberNo = selectnumber.value;
      const choosebuycardNo = choosebuycard.value;
      const chooseaddressNo = chooseaddress.value;
      const event_idNo = event_id.value;
      //配信中の購入かどうか
      const buyfromlive = true;

      // サーバーでキャプチャ処理を実行と同時にガチャ情報送付
      const capturePayment = httpsCallable(functions, "Goods_CapturePayment");
      const result = await capturePayment({
        selectnumber: selectnumberNo,
        choosebuycard: choosebuycardNo,
        chooseaddress: chooseaddressNo,
        event_id: event_idNo,
        paymentIntentId: paymentIntentId,
        shippingFee: shippingFee.value,
        buyfromlive: buyfromlive,
        paymentMethodId: paymentMethodId,
      });

      if (result.data.status === "succeeded") {
        orderId.value = result.data.stripeOrderId;
        getCreditCard();
        // console.log("aaaaaooo" + result.data.stripeOrderId);
      } else {
        alert("支払いキャプチャーに失敗しました: " + result.data.status);
        error.value = "hapen error";
      }
    } catch (err) {
      console.error("キャプチャー中にエラーが発生しました:", err.message);
      error.value = err.message;
    }
  } catch (err) {
    console.error("Error during authorization:", err.message);
    error.value = err.message;
  }
};

//paidyでの商品購入（キャプチャー）
const comfirmGoodsforpaidy = (selectnumber, chooseaddress, paidyPaymentId) => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";

  // refを消す
  const selectnumberNo = selectnumber.value;
  const chooseaddressNo = chooseaddress.value;
  const event_idNo = event_id.value;

  const buyfromlive = true;

  // Paidy決済用のCallable Functionを呼び出し
  const myCallableFunction = httpsCallable(functions, "Goods_PaidyCharge");

  myCallableFunction({
    selectnumber: selectnumberNo,
    chooseaddress: chooseaddressNo,
    event_id: event_idNo,
    shippingFee: shippingFee.value,
    buyfromlive: buyfromlive,
    paidyPaymentId: paidyPaymentId, // すでに取得済みのPaidy Payment IDを渡す
  })
    .then((result) => {
      console.log("ペイディー完了" + result);
      // 処理結果として、orderIdを取得
      orderId.value = paidyPaymentId;
    })
    .catch((err) => {
      error.value = err; // エラー情報をrefにセット
    });
};

//通常のコメントをデータベースへ書き込み
const commentinput = async (commenttext) => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  //refを消す

  const event_idNo = event_id.value;
  const normalmessageNo = commenttext.value;
  const myCallableFunction = httpsCallable(functions, "Goods_putnormalcomment");
  myCallableFunction({
    normalmessage: normalmessageNo,
    event_id: event_idNo,
  })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error.message);
    });
};

//point呼び出し
const rankingarray = ref([]);
// const rankingno1 = ref(null);
// const rankingno2 = ref(null);
// const rankingno3 = ref(null);
const getpoint = async () => {
  const querypoint = query(
    collection(db, "Goods_ranking", event_id.value, "ranking"),
    limit(3),
    orderBy("points", "desc")
  );
  // , where("capital", "==", true));
  onSnapshot(querypoint, (querySnapshot) => {
    rankingarray.value = [];

    if (querySnapshot.empty) {
      const rankingarray = [];
      return rankingarray;
    }

    // const querySnapshot = await onSnapshot(querypoint);
    querySnapshot.forEach((doc) => {
      const setranking = doc.data();
      rankingarray.value.push(setranking);
    });
  });
};
getpoint();

//vtuberのグッズの商品データを取得
const goodsData = ref([]);
const getProductsData = async () => {
  const querySnapshot = await getDocs(collection(db, "Goods_products", event_id.value, "products"));
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    goodsData.value.push(data);
  });
};

getProductsData();

const sendaddress = async (postcode, address1, address2, name, phoneno) => {
  //refを消す
  const postcodeno = postcode.value;
  const address1no = address1.value;
  const address2no = address2.value;
  const nameno = name.value;
  const phonenono = phoneno.value;
  const functions = getFunctions();
  functions.region = "asia-northeast1"; // これを追加
  const addMessage = httpsCallable(functions, "Goods_Addaddress");
  await addMessage({
    postcode: postcodeno,
    address1: address1no,
    address2: address2no,
    name: nameno,
    phoneno: phonenono,
  }).then(async (res) => {
    console.log(res);
    await getAddressData();
    // dialog.value = false;
  });
};
//表示用の金額と送料
const shippingFee = ref();
const totallPrice = ref();
const quantity = ref();
const sumPrice = ref();

const shippingfeeandorderprice = async () => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  const func = httpsCallable(functions, "Goods_calcOrderPrice_and_shippingFee");

  try {
    const res = await func({ name: "hoge" });
    shippingFee.value = res.data.price_fee.shippingFee;
    totallPrice.value = res.data.price_fee.productPrice + res.data.price_fee.shippingFee;

    const docRef = doc(db, "users", props.user.uid, "Goods_productcartitems", "productcartitems");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      quantity.value = data.quantity;
      sumPrice.value = data.quantity * unitPrice.value;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log(error.message);
  }
};

//vendor情報取得
const vendorsData = ref();
const vendorname_ja = ref();
const unitPrice = ref();
const suppercommenttype = ref();
const vendorData = async () => {
  const docRef = doc(db, "Goods_products", event_id.value);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    vendorsData.value = docSnap.data();
    vendorname_ja.value = docSnap.data().vendor_ja;
    unitPrice.value = docSnap.data().price;
    //本来は子のコンポーネントでvendorsDataから取得すべき
    suppercommenttype.value = docSnap.data().supercommenttype;
    console.log(vendorsData.value);
    console.log(suppercommenttype.value);
  } else {
    // docSnap.data() will be undefined in this case
  }
};
vendorData();

//デフォルトアドレスとデフォルトpaymentmethodの判定
const defaultAddress = ref(false);
const defaultpaymentmethod = ref(null);
const getDefaultAddress = () => {
  onSnapshot(doc(db, "users", props.user.uid, "sub_accounts", "sub_accounts"), (doc) => {
    defaultAddress.value = doc.data().default_address;
    defaultpaymentmethod.value = doc.data().default_paymentmethod;
  });
};
getDefaultAddress();

//商品のグッズガチャ購入(キャリア決裁)
const comfirmGoodsforcarrierpayment = (selectnumber, choosebuycard, chooseaddress) => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  //refを消す
  const selectnumberNo = selectnumber.value;
  // const choosebuycardNo = choosebuycard.value;
  const chooseaddressNo = chooseaddress.value;
  const event_idNo = event_id.value;
  // const cardIdNo = cardId.value;
  const myCallableFunction = httpsCallable(functions, "Goods_requestCarrierPaymentforProducts");
  myCallableFunction({
    selectnumber: selectnumberNo,
    chooseaddress: chooseaddressNo,
    event_id: event_idNo,
    shippingFee: shippingFee.value,
  })
    .then((result) => {
      window.location.href = decodeURIComponent(result.data.redirectUrl);
      // console.log(result.data.stripeOrderId);
      // orderId.value = result.data.stripeOrderId;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error.message);
    });
};
//キャリアペイメントのgoodsのダイアログを再び開いて後オーダーIDを追加
orderId.value = String(route.query.orderid);
const goods_dialogaftercarrier = ref(false);
const goods_dialogaftercarrierfortreca = ref(false);
if (orderId.value.startsWith("vl")) {
  goods_dialogaftercarrier.value = true;
} else if (orderId.value.startsWith("gt")) {
  goods_dialogaftercarrierfortreca.value = true;
} else {
  // goods_dialogaftercarrier.value = false;
}

//streaming中かどうかを判定する。streaming中でなければリダイレクト
const streamingflag = ref(true);

onSnapshot(doc(db, "Goods_products", event_id.value), (doc) => {
  streamingflag.value = doc.data().nowstreaming;
  if (streamingflag.value === false) {
    router.push(`itemlist_v?event_id=${event_id.value}`);
  } else {
    console.log("streaming");
  }
});
const changecupercommentDone = () => {
  console.log("変更");
  supercommentDone.value = false;
};
</script>
<template>
  <MainViewOrganism
    :showmessage="showmessage"
    :showranking="showranking"
    :flag="flag"
    :videourl="videourl"
    :sendmessage="sendmessage"
    :addresses="addresses"
    :defaultAddress="defaultAddress"
    :defaultpaymentmethod="defaultpaymentmethod"
    :cardsInfo="cardsInfo"
    :rankingarray="rankingarray"
    :goodsData="goodsData"
    :orderId="orderId"
    :error="error"
    :errorfortreca="errorfortreca"
    :supercommentDone="supercommentDone"
    :defaultCard="defaultCard"
    :isLoadingforcredit="isLoadingforcredit"
    :shippingFee="shippingFee"
    :totallPrice="totallPrice"
    :sumPrice="sumPrice"
    :vendorsData="vendorsData"
    :suppercommenttype="suppercommenttype"
    :goods_dialogaftercarrier="goods_dialogaftercarrier"
    :goods_dialogaftercarrierfortreca="goods_dialogaftercarrierfortreca"
    :ochaPrice="ochaPrice"
    @shippingfeeandorderprice="shippingfeeandorderprice"
    @comfirmBuy="comfirmBuy"
    @comfirmBuyforcarrierpayment="comfirmBuyforcarrierpayment"
    @comfirmGoodsforcarrierpayment="comfirmGoodsforcarrierpayment"
    @chooseGoods="chooseGoods"
    @chooseTreca="chooseTreca"
    @comfirmGoods="comfirmGoods"
    @comfirmGoodsforpaidy="comfirmGoodsforpaidy"
    @sendaddress="sendaddress"
    :key="resetKey"
    ref="component"
    @commentinput="commentinput"
    @getAddressData="getAddressData"
    @getCreditCard="getCreditCard"
    @changecupercommentDone="changecupercommentDone"
    :user="user"
    :vendorname_ja="vendorname_ja"
  />
</template>

<style scoped>
.reset {
  width: 500px;
  height: 3000px;
}
</style>
