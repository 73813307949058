<script setup>
import { ref, toRefs } from "vue";
import db from "/src/main.js"; //追加
import { collection, getDocs, query, where } from "firebase/firestore";
import { useRouter } from "vue-router";

const props = defineProps({
  vendorja: String,
  eventid: String,
});

const router = useRouter();

const { vendorja, eventid } = toRefs(props);
// const showArchiveFirst = false;

//第1回配信のアーカイブの動画URLの取得-本来は親のコンポーネントで取得してpropsで渡す
const firstArchiveVideourl = ref(null);
const firstArchiveVideourlForOndemand = ref(null); // ドキュメントIDを保持する変数を追加
const firstArchiveDocId = ref(null); // ドキュメントIDを保持する変数を追加
const getfirstarchivevideourl = async () => {
  try {
    const q = query(
      collection(db, "Goods_liveshedule"),
      where("event_id", "==", eventid.value),
      where("livenumber", "==", "第1回配信")
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      firstArchiveVideourl.value = doc.data().archive_videourl;
      //下の変数名は変更した方がいい
      firstArchiveVideourlForOndemand.value = doc.data().videouid;
      firstArchiveDocId.value = doc.id; // ドキュメントIDを取得してセット
    }
  } catch (error) {
    console.error("Error getting documents: ", error);
  }
};

// 関数呼び出し
getfirstarchivevideourl();

//第2回配信のアーカイブの動画URLの取得-本来は親のコンポーネントで取得してpropsで渡す
const secondArchiveVideourl = ref(null);
const secondArchiveVideourlForOndemand = ref(null);
const secondArchiveDocId = ref(null);

const getsecondarchivevideourl = async () => {
  try {
    const q = query(
      collection(db, "Goods_liveshedule"),
      where("event_id", "==", eventid.value),
      where("livenumber", "==", "第2回配信")
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      secondArchiveVideourl.value = doc.data().archive_videourl;
      secondArchiveVideourlForOndemand.value = doc.data().videouid;

      secondArchiveDocId.value = doc.id;
    }
  } catch (error) {
    console.error("Error getting documents: ", error);
  }
};

// 関数呼び出し
getsecondarchivevideourl();

//第3回配信のアーカイブの動画URLの取得-本来は親のコンポーネントで取得してpropsで渡す
const thirdArchiveVideourl = ref(null);
const thirdArchiveVideourlForOndemand = ref(null);
const thirdArchiveDocId = ref(null);
const getthirdarchivevideourl = async () => {
  try {
    const q = query(
      collection(db, "Goods_liveshedule"),
      where("event_id", "==", eventid.value),
      where("livenumber", "==", "第3回配信")
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      thirdArchiveVideourl.value = doc.data().archive_videourl;
      thirdArchiveVideourlForOndemand.value = doc.data().videouid;
      thirdArchiveDocId.value = doc.id;
    }
  } catch (error) {
    console.error("Error getting documents: ", error);
  }
};

// 関数呼び出し
getthirdarchivevideourl();

const toArchive = (liveid) => {
  router.push(`archive?event_id=${eventid.value}&live_id=${liveid}`);
};
</script>

<template>
  <v-container class="pa-0 mt-5">
    <v-row no-gutters>
      <v-col
        cols="12"
        md="6"
        v-if="firstArchiveVideourl !== null || firstArchiveVideourlForOndemand !== null"
        @click="toArchive(firstArchiveDocId)"
      >
        <div class="box d-flex flex-column">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/rf-prd-sukisuki-ls.appspot.com/o/live_station_images_common%2Flive_station_new_logo.png?alt=media&token=7ef25318-2c02-41c4-b74e-e93132456439"
            alt="icon"
          />
          <p>{{ vendorja }}</p>
          <p>初回配信アーカイブ&配信時間外購入</p>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="secondArchiveVideourl !== null || secondArchiveVideourlForOndemand !== null"
        @click="toArchive(secondArchiveDocId)"
      >
        <div class="box d-flex flex-column">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/rf-prd-sukisuki-ls.appspot.com/o/live_station_images_common%2Flive_station_new_logo.png?alt=media&token=7ef25318-2c02-41c4-b74e-e93132456439"
            alt="icon"
          />
          <p>{{ vendorja }}</p>
          <p>2回目配信アーカイブ&配信時間外購入</p>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="thirdArchiveVideourl !== null || thirdArchiveVideourlForOndemand !== null"
        @click="toArchive(thirdArchiveDocId)"
      >
        <div class="box d-flex flex-column">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/rf-prd-sukisuki-ls.appspot.com/o/live_station_images_common%2Flive_station_new_logo.png?alt=media&token=7ef25318-2c02-41c4-b74e-e93132456439"
            alt="icon"
          />
          <p>{{ vendorja }}</p>
          <p>3回目配信アーカイブ&配信時間外購入</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.box {
  border: 1px solid #000;
  background-color: white;
  padding: 16px;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 20px;
  cursor: pointer;
}
.icon {
  width: 10px;
}
.box img {
  width: 50%;
  height: auto;
  margin: 0 auto;
}
.box p {
  margin: 5px 0 0 0;
  font-size: 16px;
}
</style>
