<script setup>
import { toRefs } from "vue";

const props = defineProps({
  eventdatestart: String,
  eventdateend: String,
  price: String,
  shippingdate: String,
  livedatefirst: String,
  livedatesecond: String,
  livedatethird: String,
  livedatefourth: String,
  livedateend: String,
  totallivecount: Number,
});

const { eventdateend, price, shippingdate } = toRefs(props);
</script>

<template>
  <v-container class="pa-0 mt-0">
    <v-row class="maincontainer">
      <v-col cols="12" md="8" offset-md="2">
        <v-row>
          <v-col class="d-flex align-center pl-10 offset-1" cols="10">
            <span class="border-box">販売期間</span>{{ eventdateend }}まで
          </v-col>
        </v-row>

        <v-row class="align-center mt-0">
          <v-col class="d-flex align-center pl-10 offset-1" cols="10">
            <span class="border-box">販売価格</span>1回{{ price }}円<span class="small"
              >（税込）</span
            >
          </v-col>
        </v-row>
        <v-row class="align-center mt-0">
          <v-col class="d-flex align-center pl-10 offset-1" cols="10">
            <span class="border-box">送料</span>550円<span class="small"
              >（税込・20回毎にかかります）</span
            >
          </v-col>
        </v-row>
        <v-row class="align-center mt-0">
          <v-col class="d-flex align-center pl-10 offset-1" cols="10">
            <span class="border-box">お届け目安</span>{{ shippingdate }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row></v-row>
  </v-container>
</template>

<style scoped>
.maincontainer {
}
.border-box {
  border: 0.5px solid gray;
  padding: 6px 4px; /* 内側の余白 */
  border-radius: 4px; /* 角を丸くする（必要に応じて） */
  display: inline-block; /* テキスト幅だけ枠を囲む */
  margin-right: 10px;
  background-color: white;
  width: 100px;
}
.small {
  font-size: 13px;
}
@media only screen and (max-width: 768px) {
}
</style>
