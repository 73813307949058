<script setup>
import { ref, toRefs, onMounted, onUnmounted } from "vue";
import MainAutoMessage from "/src/components/molecules/MainAutoMessage.vue";
import BuyTreca from "/src/components/molecules/BuyTreca.vue";
import BuyGoods from "/src/components/molecules/BuyGoods.vue";
import VideoPlayer from "/src/components/molecules/VideoPlayer.vue";
import MainCommnetInput from "/src/components/molecules/MainCommnetInput.vue";
import MainRanking from "/src/components/molecules/MainRanking.vue";
import TheRanking from "/src/components/molecules/TheRanking.vue";
import AnimationHeart from "/src/components/molecules/AnimationHeart.vue";
import "video.js/dist/video-js.css";
import { useRouter } from "vue-router";
import MainVendorInfo from "../molecules/MainVendorInfo.vue";
const props = defineProps({
  showmessage: Array,
  showranking: Array,
  videourl: String,
  addresses: Array,
  cardsInfo: Array,
  rankingarray: Array,
  goodsData: Array,
  orderId: String,
  error: String,
  errorfortreca: String,
  supercommentDone: Boolean,
  isLoadingforcredit: Boolean,
  shippingFee: Number,
  totallPrice: Number,
  sumPrice: Number,
  vendorsData: String,
  defaultAddress: String,
  defaultCard: String,
  defaultpaymentmethod: String,
  goods_dialogaftercarrier: Boolean,
  goods_dialogaftercarrierfortreca: Boolean,
  ochaPrice: Number,
  suppercommenttype: String,
  user: String,
  vendorname_ja: String,
});
const emit = defineEmits([
  "chooseGoods",
  "chooseTreca",
  "comfirmBuy",
  "comfirmGoods",
  "commentinput",
  "sendaddress",
  "getAddressData",
  "shippingfeeandorderprice",
  "rankingclose",
  "comfirmGoodsforcarrierpayment",
  "comfirmBuyforcarrierpayment",
  "changecupercommentDone",
  "comfirmGoodsforpaidy",
]);

const { videourl } = toRefs(props);
const { goods_dialogaftercarrier } = toRefs(props);
const { goods_dialogaftercarrierfortreca } = toRefs(props);
// const { showmessage } = toRefs(props);
//以下親へ渡す
const chooseGoods = (selectnumber) => {
  emit("chooseGoods", selectnumber);
};

const chooseTreca = () => {
  emit("chooseTreca");
};

const windowWidth = ref(0);

const calculateWindowWidth = () => {
  windowWidth.value = window.innerWidth;
};
calculateWindowWidth();

const isMuted = ref(true);
const toggleMute = () => {
  isMuted.value = !isMuted.value; // 現在の値を反転させる
};

const goods_dialog = ref(false);
const ranking_dialog = ref(false);
const card_dialog = ref(false);
const cardClose = () => {
  console.log("cardClose");
  card_dialog.value = false;
  emit("changecupercommentDone");
};
const goodsClose = () => {
  goods_dialog.value = false;
};
const comfirmBuy = (selectitem, choosebuycard, comments, chooseaddress, cardId, trecaid) => {
  emit("comfirmBuy", selectitem, choosebuycard, comments, chooseaddress, cardId, trecaid);
};
const comfirmGoods = (selectnumber, choosebuycard, chooseaddress, cardId) => {
  emit("comfirmGoods", selectnumber, choosebuycard, chooseaddress, cardId);
};
const comfirmGoodsforcarrierpayment = (selectnumber, choosebuycard, chooseaddress, cardId) => {
  emit("comfirmGoodsforcarrierpayment", selectnumber, choosebuycard, chooseaddress, cardId);
};
const comfirmGoodsforpaidy = (selectnumber, chooseaddress, paidyPaymentId) => {
  emit("comfirmGoodsforpaidy", selectnumber, chooseaddress, paidyPaymentId);
};

const comfirmBuyforcarrierpayment = (
  selectitem,
  choosebuycard,
  comments,
  chooseaddress,
  cardId,
  trecaid
) => {
  emit(
    "comfirmBuyforcarrierpayment",
    selectitem,
    choosebuycard,
    comments,
    chooseaddress,
    cardId,
    trecaid
  );
};

const sendaddress = (postcode, address1, address2, name, phoneno) => {
  emit("sendaddress", postcode, address1, address2, name, phoneno);
};

const commentinput = (commenttext) => {
  emit("commentinput", commenttext);
};

const getAddressData = () => {
  emit("getAddressData");
};

const getCreditCard = () => {
  emit("getCreditCard");
};
const shippingfeeandorderprice = () => {
  emit("shippingfeeandorderprice");
};

// shippingfeeandorderprice;
// getCreditCard;
const router = useRouter();
const backToHome = () => {
  router.push("/");
};

const rankingclose = () => {
  ranking_dialog.value = false;
};

onMounted(() => {
  if (goods_dialogaftercarrier.value == true) {
    goods_dialog.value = true;
  }
  if (goods_dialogaftercarrierfortreca.value == true) {
    // card_dialog.value = true;
  }
});

//画面出し分け
// リアクティブな変数を作成
const isPc = ref(false);

// メディアクエリのマッチ時の処理
function updateMatch(e) {
  isPc.value = e.matches;
}

onMounted(() => {
  const mediaQuery = window.matchMedia("(min-width: 960px)");
  mediaQuery.addEventListener("change", updateMatch);
  // 初期値のセット
  updateMatch(mediaQuery);

  onUnmounted(() => {
    // コンポーネントの破棄時にリスナーを削除
    mediaQuery.removeEventListener("change", updateMatch);
  });
});
const handleClickGacha = () => {
  card_dialog.value = false;
  goods_dialog.value = true;
};
const handleClickCard = () => {
  goods_dialog.value = false;
  card_dialog.value = true;
};
</script>

<template>
  <!-- PCの場合-->
  <div v-if="isPc">
    <div class="pc_container">
      <!-- 動画 -->
      <div class="pc_movie_area">
        <VideoPlayer :videourl="videourl" :isMuted="isMuted" />
      </div>
      <!-- 情報エリア -->
      <div class="pc_info_area">
        <div class="pc_info_area_inner">
          <div class="btn_sound">
            <!-- 音ボタンの挙動-->
            <img v-if="isMuted" @click="toggleMute()" src="img/soundoff.png" />
            <img v-if="!isMuted" @click="toggleMute()" src="img/soundon.png" />
          </div>
          <div class="btn_ocha">
            <img src="img/btn_ocha.png" />
          </div>
          <div class="ocha_number">
            <p>{{ ochaPrice }}</p>
          </div>
          <!-- header -->
          <div class="pc_header">
            <div class="pc_head_inner">
              <!-- 配信者・配信情報 -->
              <MainVendorInfo :vendorsData="vendorsData" />
              <div class="ranking">
                <div class="ranking_list">
                  <!-- 新しいmoleculesのvueを作成し内容を移植する -->
                  <MainRanking :rankingarray="rankingarray" />
                </div>
                <div class="ranking_button">
                  <img @click="ranking_dialog = true" src="img/btn_ranking.png" />
                  <TheRanking
                    :rankingarray="rankingarray"
                    :ranking_dialog="ranking_dialog"
                    @rankingclose="rankingclose"
                  />
                </div>
              </div>
              <!-- ランキング情報 END -->
            </div>
          </div>
          <!-- header END -->
          <!-- コメント掲載エリア -->
          <div class="pc_comment_list">
            <div class="pc_comment_list_inner">
              <MainAutoMessage :showmessage="showmessage" :rankingarray="rankingarray" />
            </div>
          </div>
          <!-- コメント掲載エリア -->
          <!-- footer -->
          <div class="pc_footer">
            <div class="pc_footer_inner">
              <!-- コメントエリア -->
              <div class="pc_comment_area">
                <!-- コメント入力 未実装 -->
                <MainCommnetInput @commentinput="commentinput" />
                <!-- <input
              class="comment"
              type="text"
              placeholder="コメントする"
              v-model="commenttext"
              @keypress.enter="onKeypressEnter"
            /> -->
              </div>
              <!-- コメントエリア END -->
              <!-- アイテムエリア -->

              <div class="item_list">
                <ul>
                  <!-- ハート-->
                  <AnimationHeart />

                  <!-- グッズガチャ -->
                  <li>
                    <img class="btngacha" @click="handleClickGacha" src="img/btn_gacha.png" />
                  </li>
                  <BuyGoods
                    :goods_dialog="goods_dialog"
                    :addresses="addresses"
                    :cardsInfo="cardsInfo"
                    :goodsData="goodsData"
                    :orderId="orderId"
                    :error="error"
                    :isLoadingforcredit="isLoadingforcredit"
                    :shippingFee="shippingFee"
                    :totallPrice="totallPrice"
                    :sumPrice="sumPrice"
                    :defaultAddress="defaultAddress"
                    :defaultCard="defaultCard"
                    :defaultpaymentmethod="defaultpaymentmethod"
                    :goods_dialogaftercarrier="goods_dialogaftercarrier"
                    @goodsclose="goodsClose"
                    @chooseGoods="chooseGoods"
                    @comfirmGoods="comfirmGoods"
                    @sendaddress="sendaddress"
                    @getAddressData="getAddressData"
                    @getCreditCard="getCreditCard"
                    @shippingfeeandorderprice="shippingfeeandorderprice"
                    @comfirmGoodsforcarrierpayment="comfirmGoodsforcarrierpayment"
                    @comfirmGoodsforpaidy="comfirmGoodsforpaidy"
                    :user="user"
                    :vendorname_ja="vendorname_ja"
                  />
                  <!-- カード購入 -->
                  <li><img class="btncard" @click="handleClickCard" src="img/btn_card.png" /></li>
                  <BuyTreca
                    :card_dialog="card_dialog"
                    :addresses="addresses"
                    :cardsInfo="cardsInfo"
                    :defaultAddress="defaultAddress"
                    :defaultCard="defaultCard"
                    :defaultpaymentmethod="defaultpaymentmethod"
                    :goods_dialogaftercarrierfortreca="goods_dialogaftercarrierfortreca"
                    :supercommentDone="supercommentDone"
                    :errorfortreca="errorfortreca"
                    :suppercommenttype="suppercommenttype"
                    @cardclose="cardClose"
                    @comfirmBuy="comfirmBuy"
                    @getAddressData="getAddressData"
                    @sendaddress="sendaddress"
                    @getCreditCard="getCreditCard"
                    @comfirmBuyforcarrierpayment="comfirmBuyforcarrierpayment"
                    @chooseTreca="chooseTreca"
                  />
                </ul>
              </div>
              <!-- アイテムエリア END -->
            </div>
          </div>
          <!-- <AnimationHeart :animationheatfun="animationheatfun" /> -->
          <!-- footer END -->
        </div>
      </div>
    </div>
  </div>

  <!-- スマホの場合-->
  <div v-else class="container">
    <div class="btn_close">
      <!-- 閉じるボタンの挙動-->
      <img @click="backToHome()" src="img/btn_close.png" />
    </div>
    <div class="btn_sound">
      <!-- 音ボタンの挙動-->
      <img v-if="isMuted" @click="toggleMute()" src="img/soundoff.png" />
      <img v-if="!isMuted" @click="toggleMute()" src="img/soundon.png" />
    </div>
    <div class="btn_ocha">
      <img src="img/btn_ocha.png" />
    </div>
    <div class="ocha_number">
      <p>{{ ochaPrice }}</p>
    </div>

    <!-- 動画 -->
    <VideoPlayer :videourl="videourl" :isMuted="isMuted" />
    <!-- header -->
    <div class="header">
      <div class="head_inner">
        <!-- 配信者・配信情報 -->
        <MainVendorInfo :vendorsData="vendorsData" />
        <!-- <div class="distributor"> -->
        <!-- <div class="distributor_icon"> -->
        <!-- 配信者のアイコンを表示させる 未実装 -->
        <!-- <img src="img/icon_distributor.png" />
          </div>
          <div class="distributor_info"> -->
        <!-- 配信者の情報を表示させる 未実装 -->
        <!-- <div class="distributor_name">配信者名配信者名配信者名配信者名配信者名</div>
            <div class="distributor_eventname"> -->
        <!-- イベントの情報を表示させる 未実装 -->
        <!-- イベント名イベント名イベント名イベント名イベント名イベント名イベント名
            </div>
          </div>
        </div> -->
        <!-- 配信者・配信情報 END -->
        <!-- ランキング情報 -->
        <div class="ranking">
          <div class="ranking_list">
            <!-- 新しいmoleculesのvueを作成し内容を移植する -->
            <MainRanking :rankingarray="rankingarray" />
          </div>
          <div class="ranking_button">
            <img @click="ranking_dialog = true" src="img/btn_ranking.png" />
            <TheRanking
              :rankingarray="rankingarray"
              :ranking_dialog="ranking_dialog"
              @rankingclose="rankingclose"
            />
          </div>
        </div>
        <!-- ランキング情報 END -->
      </div>
    </div>
    <!-- header END -->

    <!-- コメント掲載エリア -->
    <div class="comment_list">
      <div class="comment_list_inner">
        <MainAutoMessage :showmessage="showmessage" :rankingarray="rankingarray" />
      </div>
    </div>
    <!-- コメント掲載エリア -->

    <!-- footer -->
    <div class="footer">
      <div class="footer_inner">
        <!-- コメントエリア -->
        <div class="comment_area">
          <!-- コメント入力 未実装 -->
          <MainCommnetInput @commentinput="commentinput" />
          <!-- <input
            class="comment"
            type="text"
            placeholder="コメントする"
            v-model="commenttext"
            @keypress.enter="onKeypressEnter"
          /> -->
        </div>
        <!-- コメントエリア END -->
        <!-- アイテムエリア -->

        <div class="item_list">
          <ul>
            <!-- ハート-->
            <AnimationHeart />

            <!-- グッズガチャ -->
            <li>
              <img
                img
                class="btngacha"
                @click="(card_dialog = false), (goods_dialog = true)"
                src="img/btn_gacha.png"
              />
            </li>
            <BuyGoods
              :goods_dialog="goods_dialog"
              :addresses="addresses"
              :cardsInfo="cardsInfo"
              :goodsData="goodsData"
              :orderId="orderId"
              :error="error"
              :isLoadingforcredit="isLoadingforcredit"
              :shippingFee="shippingFee"
              :totallPrice="totallPrice"
              :sumPrice="sumPrice"
              :defaultAddress="defaultAddress"
              :defaultCard="defaultCard"
              :defaultpaymentmethod="defaultpaymentmethod"
              :goods_dialogaftercarrier="goods_dialogaftercarrier"
              @goodsclose="goodsClose"
              @chooseGoods="chooseGoods"
              @comfirmGoods="comfirmGoods"
              @sendaddress="sendaddress"
              @getAddressData="getAddressData"
              @getCreditCard="getCreditCard"
              @shippingfeeandorderprice="shippingfeeandorderprice"
              @comfirmGoodsforcarrierpayment="comfirmGoodsforcarrierpayment"
              @comfirmGoodsforpaidy="comfirmGoodsforpaidy"
              :user="user"
              :vendorname_ja="vendorname_ja"
            />
            <!-- カード購入 -->
            <li>
              <img
                class="btncard"
                @click="(card_dialog = true), (goods_dialog = false)"
                src="img/btn_card.png"
              />
            </li>
            <BuyTreca
              :card_dialog="card_dialog"
              :addresses="addresses"
              :cardsInfo="cardsInfo"
              :defaultAddress="defaultAddress"
              :defaultCard="defaultCard"
              :defaultpaymentmethod="defaultpaymentmethod"
              :goods_dialogaftercarrierfortreca="goods_dialogaftercarrierfortreca"
              :errorfortreca="errorfortreca"
              :supercommentDone="supercommentDone"
              :suppercommenttype="suppercommenttype"
              @cardclose="cardClose"
              @comfirmBuy="comfirmBuy"
              @getAddressData="getAddressData"
              @sendaddress="sendaddress"
              @getCreditCard="getCreditCard"
              @comfirmBuyforcarrierpayment="comfirmBuyforcarrierpayment"
              @chooseTreca="chooseTreca"
            />
          </ul>
        </div>
        <!-- アイテムエリア END -->
      </div>
    </div>
    <!-- <AnimationHeart :animationheatfun="animationheatfun" /> -->
    <!-- footer END -->
  </div>
  <!-- container END -->
</template>

<style scoped>
.container {
  height: 100%;
  background-color: #333;
  touch-action: manipulation; /* モバイルでの拡大を禁止 */
  zoom: 1; /* ブラウザでの拡大を禁止 */
}

.btn_close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  z-index: 1000;
  cursor: pointer;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
}
.btn_close img {
  width: 100%;
}
.btn_sound {
  position: absolute;
  top: 120px;
  right: 17px;
  width: 30px;
  z-index: 1000;
  cursor: pointer;
}
.btn_sound img {
  width: 120%;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
}
.btn_ocha {
  position: absolute;
  top: 170px;
  right: 18px;
  width: 30px;
  z-index: 1000;
}
.btn_ocha img {
  width: 120%;
  z-index: 1000;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
}
.ocha_number {
  position: absolute;
  top: 210px;
  right: 18px;
  width: 30px;
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: 700; /* 太字を指定 */
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
  display: flex;
  justify-content: center; /* 水平方向に中央揃え */
  align-items: center; /* 垂直方向に中央揃え */
  z-index: 1000;
}
/* ヘッダー ---------------------------------------------  */
.header {
  position: absolute;
  top: 15px;
  width: 100%;
  z-index: 2;
  /*background-color: #123123;*/
}
.header .pc_head_inner {
  margin-left: 12px;
  margin-right: 12px;
}

.ranking {
  display: flex;
  justify-content: space-between;
  position: relative; /* 親要素に position: relative を追加 */
}
.ranking .ranking_list {
  width: calc(100% - 50px);
  overflow: hidden; /* overflow を hidden に変更してスクロールバーを非表示に */
}
.ranking .ranking_button {
  width: 40px;
  position: absolute; /* 位置を絶対指定 */
  right: 10px; /* 右から10pxの位置に設定 */
  cursor: pointer;
}
.ranking_button img {
  width: 100%;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
}
/* ランキングのulは新しいmoleculesのvueを作成し内容を移植する
.ranking_list ul {
  display: flex;
  width: 5000px;
}
.ranking_list ul li {
  width: 30px;
  margin-right: 5px;
}
.ranking_list ul li img {
  width: 100%;
}
 */
/* ランキング END */
/* ヘッダー END --------------------------------------------- */

/* コメント */
.comment_list {
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 50px;
  /*background-color: #aaa;*/
  overflow: scroll;
  z-index: 3;
}
.comment_list .comment_list_inner {
  margin-left: 12px;
  margin-right: 12px;
}
/* コメント END */

/* footer --------------------------------------------- */
.footer {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 5px;
  z-index: 1;
}
.footer .footer_inner {
  margin-left: 12px;
  margin-right: 12px;
}
.footer_inner {
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
}
.comment_area {
  width: calc(100% - 150px);
}
/* .comment_area .comment {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  padding: 0px 10px;
  background: rgba(255, 255, 255, 0.9);
} */
.comment_area .comment:focus {
  border: 0;
}
.item_list {
  z-index: 1;
}
.item_list ul {
  display: flex;
  flex-wrap: no-wrap;
}
.item_list ul li {
  width: 40px;
  margin-left: 10px;
}
.item_list ul li img {
  width: 100%;
}

/* footer END --------------------------------------------- */

.btngacha {
  cursor: pointer;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
}
.btncard {
  cursor: pointer;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
}

@media (min-width: 960px) {
  #app {
    height: 10%;
  }
  .pc_container {
    display: flex;
  }
  .pc_movie_area {
    width: 50%;
    height: 80vh;
    background-color: #666;
  }
  .pc_info_area {
    width: 50%;
    height: 80vh;
    background-color: rgb(125, 125, 125);
    padding: 20px;
  }
  .pc_info_area_inner {
    position: relative;
    height: 100%;
    /*box-sizing: border-box;*/
  }
  .btn_sound {
    position: absolute;
    top: 0px;
    right: 15px;
    width: 30px;
    z-index: 1000;
    cursor: pointer;
  }
  .btn_ocha {
    position: absolute;
    top: 100px;
    right: 18px;
    width: 30px;
    z-index: 0;
  }
  .btn_ocha img {
    width: 120%;
    z-index: 0;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
  }
  .ocha_number {
    position: absolute;
    top: 140px;
    right: 18px;
    width: 30px;

    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 700; /* 太字を指定 */
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
    display: flex;
    justify-content: center; /* 水平方向に中央揃え */
    align-items: center; /* 垂直方向に中央揃え */
    z-index: 0;
  }
  .pc_comment_list {
    height: calc(100% - 140px);
    overflow: scroll;
  }
  .pc_comment_list_inner {
  }
  .pc_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .pc_header {
    width: 100%;
    z-index: 2;
    /*background-color: #123123;*/
  }
  .pc_header .head_inner {
  }
  .pc_footer .pc_footer_inner {
  }
  .pc_footer_inner {
    display: flex;
    flex-wrap: no-wrap;
    justify-content: space-between;
  }
  .pc_comment_area {
    width: 100%;
  }
  .footer {
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 5px;
    z-index: 1;
  }
}
</style>
