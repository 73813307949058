<script setup>
import { ref } from "vue";
import { stripe } from "/src/stripe.js";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import BannerPicture from "/src/components/atoms/BannerPicture.vue";
import CampaignTitleBar from "/src/components/atoms/CampaignTitleBar.vue";
import SellInfoForOutOfHours from "/src/components/atoms/SellInfoForOutOfHours.vue";
import BuyGoodsForOutOfHours from "/src/components/molecules/BuyGoodsForOutOfHours.vue";

import { formatDateJaFull } from "@/utils/date.js";

import db from "../main.js"; //追加
import { onSnapshot, doc, setDoc, getDocs, collection, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useRoute } from "vue-router";
const props = defineProps({
  user: String,
});
import { useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const event_id = ref(route.query.event_id);
const vtuber_image = ref(null);
const event_title = ref(null);
const eventdatestart = ref(null);
const eventdateend = ref(null);
const price = ref(null);
const unitPrice = ref();
const shippingdate = ref(null);
const nowstreaming = ref(false);
const livedatefirst = ref();
const livedatesecond = ref();
const livedatethird = ref();
const livedatefourth = ref();
const livedateend = ref();
const totallivecount = ref();
const vendorname_ja = ref();

dayjs.extend(customParseFormat); // フォーマット指定のためにプラグインを拡張

const getEventData = async () => {
  // const docRef = doc(db, "Goods_products", vendorname);
  onSnapshot(doc(db, "Goods_products", event_id.value), (doc) => {
    const data = doc.data();
    vtuber_image.value = data.vendor_image;
    event_title.value = data.eventtitle;
    vendorname_ja.value = doc.data().vendor_ja;

    eventdatestart.value = formatDateJaFull(data.eventdate.start.toDate()).replace(/:\d{2}$/, "");
    eventdateend.value = formatDateJaFull(data.eventdate.end.toDate()).replace(/:\d{2}$/, "");
    price.value = data.price;
    unitPrice.value = data.price;
    shippingdate.value = data.shipping_date;
    nowstreaming.value = data.nowstreaming;
    livedatefirst.value = data.livedate.first
      ? formatDateJaFull(data.livedate.first.toDate()).replace(/:\d{2}$/, "")
      : null;
    livedatesecond.value = data.livedate.second
      ? formatDateJaFull(data.livedate.second.toDate()).replace(/:\d{2}$/, "")
      : null;
    livedatethird.value = data.livedate.third
      ? formatDateJaFull(data.livedate.third.toDate()).replace(/:\d{2}$/, "")
      : null;
    livedatefourth.value = data.livedate.fourth
      ? formatDateJaFull(data.livedate.fourth.toDate()).replace(/:\d{2}$/, "")
      : null;
    livedateend.value = data.livedate.end
      ? formatDateJaFull(data.livedate.end.toDate()).replace(/:\d{2}$/, "")
      : null;
    totallivecount.value = data.totallivecount;

    console.log("Event end date:", eventdateend.value);
  });
};

getEventData();

// 現在時刻
// const now = ref(dayjs());

// イベントが終了したかどうかの条件を計算
// const isEventend = computed(() => {
//   if (!eventdateend.value) return false;

//   // eventdateend.value が正しい形式であることを前提に、 dayjs オブジェクトを作成
//   const eventEndTime = dayjs(eventdateend.value, "YYYY年MM月DD日HH:mm");

//   // 現在時刻も dayjs オブジェクトに変換
//   const currentTime = dayjs(now.value);

//   console.log("Event end time:", eventEndTime);
//   console.log("Current time:", currentTime);

//   // 現在時刻がイベント終了時刻を過ぎているかを判定
//   return currentTime.isAfter(eventEndTime);
// });

const chooseGoods = async (selectnumber) => {
  if (props.user) {
    //カートへ書き込み
    await setDoc(
      doc(db, "users", props.user.uid, "Goods_productcartitems", "productcartitems"),
      {
        quantity: selectnumber.value,
        event_id: event_id.value,
        vendorname_ja: vendorname_ja.value,
      },
      {
        merge: false,
      }
    );
  }
};

const addresses = ref([]);

async function getAddressData() {
  addresses.value = [];
  const querySnapshot = await getDocs(collection(db, "users", props.user.uid, "addresses"));
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    addresses.value.push(data);
  });
}
getAddressData();

const sendaddress = async (postcode, address1, address2, name, phoneno) => {
  //refを消す
  const postcodeno = postcode.value;
  const address1no = address1.value;
  const address2no = address2.value;
  const nameno = name.value;
  const phonenono = phoneno.value;
  const functions = getFunctions();
  functions.region = "asia-northeast1"; // これを追加
  const addMessage = httpsCallable(functions, "Goods_Addaddress");
  await addMessage({
    postcode: postcodeno,
    address1: address1no,
    address2: address2no,
    name: nameno,
    phoneno: phonenono,
  }).then(async (res) => {
    console.log(res);
    await getAddressData();
    // dialog.value = false;
  });
};

//登録済みクレジットカード呼び出し
const cardsInfo = ref(null);
const defaultCard = ref(null);
const isLoadingforcredit = ref(true);
async function getCreditCard() {
  console.log("getCreditCard");
  isLoadingforcredit.value = true;
  const functions = getFunctions();
  functions.region = "asia-northeast1";

  const myCallableFunction = httpsCallable(functions, "Common_cardslist");
  myCallableFunction({})
    .then((result) => {
      cardsInfo.value = result.data.cards;
      defaultCard.value = result.data.defaultCardId;

      isLoadingforcredit.value = false;
    })
    .catch((error) => {
      console.log(error.message);
    });
}

//表示用の金額と送料
const shippingFee = ref();
const totallPrice = ref();
const quantity = ref();
const sumPrice = ref();

const shippingfeeandorderprice = async () => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  const func = httpsCallable(functions, "Goods_calcOrderPrice_and_shippingFee");

  try {
    const res = await func({ name: "hoge" });
    shippingFee.value = res.data.price_fee.shippingFee;
    totallPrice.value = res.data.price_fee.productPrice + res.data.price_fee.shippingFee;

    const docRef = doc(db, "users", props.user.uid, "Goods_productcartitems", "productcartitems");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      quantity.value = data.quantity;
      sumPrice.value = data.quantity * unitPrice.value;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log(error.message);
  }
};

const error = ref(null); // エラー情報を保持するためのref
//商品のグッズガチャ購入(クレジットカード)selectnumber, choosebuycard, chooseaddress, paymentMethodId
const orderId = ref();
const comfirmGoods = async (selectnumber, choosebuycard, chooseaddress, paymentMethodId) => {
  const functions = getFunctions();
  const createPaymentIntent = httpsCallable(functions, "Goods_CreatePaymentIntent");

  try {
    const result = await createPaymentIntent({
      paymentMethodId,
    });
    const clientSecret = result.data.clientSecret; // 修正ポイント
    const paymentIntentId = result.data.paymentIntentId;
    // オーソリが成功

    //    ここで次のステップに進む

    try {
      // クライアントで3Dセキュア認証を実行
      const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId,
        setup_future_usage: "on_session", // サービス内でユーザーが即時購入する場合
      });

      if (confirmError) {
        console.error("3Dセキュア認証エラー:", confirmError.message);
        error.value = confirmError;
        return;
      }

      if (paymentIntent.status !== "requires_capture") {
        console.error("キャプチャ可能な状態ではありません:", paymentIntent.status);
        error.value = "hapen error";
        // error.value = error.message;
        return;
      }

      //refを消す
      const selectnumberNo = selectnumber.value;
      const choosebuycardNo = choosebuycard.value;
      const chooseaddressNo = chooseaddress.value;
      const event_idNo = event_id.value;
      //配信中の購入かどうか
      const buyfromlive = false;

      // サーバーでキャプチャ処理を実行と同時にガチャ情報送付
      const capturePayment = httpsCallable(functions, "Goods_CapturePayment");
      const result = await capturePayment({
        selectnumber: selectnumberNo,
        choosebuycard: choosebuycardNo,
        chooseaddress: chooseaddressNo,
        event_id: event_idNo,
        paymentIntentId: paymentIntentId,
        shippingFee: shippingFee.value,
        buyfromlive: buyfromlive,
        paymentMethodId: paymentMethodId,
      });

      if (result.data.status === "succeeded") {
        orderId.value = result.data.stripeOrderId;
        getCreditCard();
        infoFlag.value = false;
        // console.log("aaaaaooo" + result.data.stripeOrderId);
      } else {
        alert("支払いキャプチャーに失敗しました: " + result.data.status);
        error.value = "hapen error";
      }
    } catch (err) {
      console.error("キャプチャー中にエラーが発生しました:", err.message);
      error.value = err.message;
    }
  } catch (err) {
    console.error("Error during authorization:", err.message);
    error.value = err.message;
  }
};

//paidyでの商品購入（キャプチャー）
const comfirmGoodsforpaidy = (selectnumber, chooseaddress, paidyPaymentId) => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";

  // refを消す
  const selectnumberNo = selectnumber.value;
  const chooseaddressNo = chooseaddress.value;
  const event_idNo = event_id.value;

  const buyfromlive = false;

  // Paidy決済用のCallable Functionを呼び出し
  const myCallableFunction = httpsCallable(functions, "Goods_PaidyCharge");

  myCallableFunction({
    selectnumber: selectnumberNo,
    chooseaddress: chooseaddressNo,
    event_id: event_idNo,
    shippingFee: shippingFee.value,
    buyfromlive: buyfromlive,
    paidyPaymentId: paidyPaymentId, // すでに取得済みのPaidy Payment IDを渡す
  })
    .then((result) => {
      console.log("ペイディー完了" + result);
      // 処理結果として、orderIdを取得
      orderId.value = paidyPaymentId;
      infoFlag.value = false;
    })
    .catch((err) => {
      error.value = err; // エラー情報をrefにセット
    });
};

//デフォルトアドレスとデフォルトpaymentmethodの判定
const defaultAddress = ref(false);
const defaultpaymentmethod = ref(null);
const getDefaultAddress = () => {
  onSnapshot(doc(db, "users", props.user.uid, "sub_accounts", "sub_accounts"), (doc) => {
    defaultAddress.value = doc.data().default_address;
    defaultpaymentmethod.value = doc.data().default_paymentmethod;
  });
};
getDefaultAddress();

//動画中に上のInfoをなくす
const infoFlag = ref(true);

const goodsclose = () => {
  infoFlag.value = true;
};

const toItemPage = () => {
  router.push(`itemlist_v?event_id=${event_id.value}`);
  // this.$router.push(`/itemlist?vtubername=${card.vtuber_en}`, () => {});
};
</script>
<template>
  <v-row>
    <v-col v-show="infoFlag" cols="12" class="mt-9">
      <h3>時間外購入ページ</h3>
    </v-col>
    <v-row v-show="infoFlag" class="background mt-7">
      <v-col cols="12" class="mt-2 bannerpic">
        <BannerPicture :vtuber_image="vtuber_image" />
      </v-col>

      <v-col>
        <CampaignTitleBar :event_title="event_title" />
      </v-col>

      <v-col cols="12">
        <SellInfoForOutOfHours
          :eventdatestart="eventdatestart"
          :eventdateend="eventdateend"
          :price="price"
          :shippingdate="shippingdate"
        />
      </v-col>
    </v-row>
    <v-col cols="12">
      <BuyGoodsForOutOfHours
        :user="user"
        :goodsData="goodsData"
        :addresses="addresses"
        :defaultAddress="defaultAddress"
        :defaultpaymentmethod="defaultpaymentmethod"
        :isLoadingforcredit="isLoadingforcredit"
        :cardsInfo="cardsInfo"
        :defaultCard="defaultCard"
        :shippingFee="shippingFee"
        :totallPrice="totallPrice"
        :sumPrice="sumPrice"
        :error="error"
        :orderId="orderId"
        @chooseGoods="chooseGoods"
        @getAddressData="getAddressData"
        @sendaddress="sendaddress"
        @getCreditCard="getCreditCard"
        @shippingfeeandorderprice="shippingfeeandorderprice"
        @comfirmGoods="comfirmGoods"
        @comfirmGoodsforpaidy="comfirmGoodsforpaidy"
        @goodsclose="goodsclose"
        @toItemPage="toItemPage"
      />
    </v-col>
  </v-row>
</template>

<style scoped>
.title {
  margin: 40px 0 30px 0;
}
.boxtitle {
  border: solid 1px rgb(218, 57, 144);
  display: inline-block;
  padding: 15px 15px 15px 15px;
  background-color: rgb(218, 57, 144);
  border-radius: 20px 20px 20px 20px;
  color: white;
  cursor: pointer;

  /* text-align: left; */
}
.boxtitlebefore {
  border: solid 1px rgb(204, 204, 204);
  display: inline-block;
  padding: 15px 15px 15px 15px;
  background-color: rgb(204, 204, 204);
  border-radius: 20px 20px 20px 20px;
  color: white;

  /* text-align: left; */
}
.attention {
  margin: 0px 5px 0px 5px;
}

.background {
  background-color: #f5f5f5;
  padding-bottom: 10px;
}

@media (max-width: 960px) {
  .bannerpic {
    padding-left: 20%;
    padding-right: 20%;
  }
}
</style>
