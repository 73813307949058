<template>
  <teleport to="body">
    <div v-if="showNotice" class="popup-overlay">
      <div class="popup-container">
        <!-- <button class="close-btn" @click="closeNotice">×</button> -->
        <h2 class="popup-title">お知らせ</h2>
        <p v-html="noticeMessage"></p>
        <div class="popup-footer">
          <button class="confirm-btn" @click="closeNotice">OK</button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getFunctions, httpsCallable } from "firebase/functions";

// メッセージに改行を含むHTML文字列
const noticeMessage = ref(
  "さらなるセキュリティ強化のため、クレジットカード決済において3Dセキュア認証に対応いたしました。<br>それに伴い、クレジットカードをご登録いただいているユーザー様には、再度ご登録をお願い申し上げます"
);

// 通知の表示状態
const showNotice = ref(false);

// 通知を閉じる関数
const closeNotice = () => {
  showNotice.value = false;
  localStorage.setItem("cardNoticeDismissed", "true"); // 通知を閉じた状態を保存
};

// Firebase Function を呼び出して旧カードの確認
const checkForOldCard = async () => {
  try {
    const functions = getFunctions();
    functions.region = "asia-northeast1";
    const checkOldCardFunction = httpsCallable(functions, "Common_checkOldCardStatus");

    const result = await checkOldCardFunction();
    const hasOldCard = result.data.hasOldCard; // 旧カード登録の有無を取得

    if (hasOldCard && localStorage.getItem("cardNoticeDismissed") !== "true") {
      showNotice.value = true; // ポップアップを表示
    }
    // showNotice.value = true; // ポップアップを表示
  } catch (error) {
    console.error("旧カードの確認中にエラーが発生しました:", error.message);
    // showNotice.value = true; // ポップアップを表示
  }
};

// コンポーネントがマウントされたときにチェックを実行
onMounted(() => {
  checkForOldCard();
});
</script>

<style scoped>
/* ポップアップの全体背景 */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明の黒背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* ポップアップコンテナ */
.popup-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
}

/* ポップアップのタイトル */
.popup-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

/* メッセージ */
.popup-container p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
  white-space: normal;
}

/* 閉じるボタン */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #999;
  cursor: pointer;
}

.close-btn:hover {
  color: #333;
}

/* フッターボタン */
.popup-footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.confirm-btn {
  padding: 10px 20px;
  background-color: #808080;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-btn:hover {
  background-color: #696969;
}
</style>
