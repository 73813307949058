<script setup>
import { ref } from "vue";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useRouter } from "vue-router";
import AddCard from "/src/components/molecules/AddCard.vue";

const cardsInfo = ref(null);
const defaultCard = ref(null);
async function getCreditCard() {
  const functions = getFunctions();
  functions.region = "asia-northeast1";

  const myCallableFunction = httpsCallable(functions, "Common_cardslist");
  myCallableFunction({})
    .then((result) => {
      cardsInfo.value = result.data.cards;
      defaultCard.value = result.data.defaultCardId;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error.message);
    });
}
getCreditCard();

const router = useRouter();
const toLink = (url) => {
  router.push(url);
};

const dialog = ref(false);
const dialogfun = () => {
  dialog.value = true;
};

const addcardclose = () => {
  dialog.value = false;
};

const addcardcloseandload = () => {
  dialog.value = false;
  getCreditCard();
};

const selectdeleteCard = (index) => {
  const functions = getFunctions();
  functions.region = "asia-northeast1"; // これを追加
  const addMessage = httpsCallable(functions, "Common_deleteCard");
  const cardId = cardsInfo.value[index].id;

  addMessage({ card_id: cardId })
    .then((result) => {
      console.log(result);
      getCreditCard();
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error.message);
    });
};

const setDefaulcard = async (id) => {
  const functions = getFunctions();
  functions.region = "asia-northeast1"; // これを追加
  const addMessage = httpsCallable(functions, "Goods_SetDefaultCard");
  await addMessage({
    paymentMethodId: id,
  }).then((res) => {
    console.log(res);
    // dialog.value = false;
    location.reload();
  });
};
</script>
<template>
  <v-app>
    <div>
      <h2 class="mt-12 title">お支払い先情報</h2>
    </div>
    <div v-for="(card, index) in cardsInfo" :key="index" class="pa-2 box" xs12 md9>
      <div class="cardbrand">
        {{ card.brand }}
      </div>
      <div class="cardno">**** **** **** {{ card.card.last4 }}</div>
      <div class="expireAndDefault">
        <div class="cardexpire">有効期限 {{ card.card.exp_month }} / {{ card.card.exp_year }}</div>
        <div v-if="card.id === defaultCard" class="defaultCard">デフォルト</div>
        <div v-if="card.id !== defaultCard" class="setdefaultCard" @click="setDefaulcard(card.id)">
          デフォルトに設定
        </div>
      </div>
      <div class="carddelete" color="error" @click="selectdeleteCard(index)">ー削除</div>
    </div>

    <div>
      <v-btn class="mt-5 addbobtn" height="25" width="134" rounded="pill" @click="dialogfun()">
        <a class="addpayment">+お支払い情報の追加</a>
      </v-btn>
    </div>
    <v-dialog v-model="dialog" max-width="400">
      <AddCard @addcardclose="addcardclose" @addcardcloseandload="addcardcloseandload" />
    </v-dialog>
    <div class="returncontainer" @click="toLink('myaccount')">
      <v-btn height="30" width="65" rounded="pill" class="btn">
        <a class="return">戻る</a>
      </v-btn>
    </div>
  </v-app>
</template>

<style scoped>
/* .form {
  width: 100%;
} */
.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 14px;
  margin: 0 0 10px 0;
}
.box {
  border-radius: 15px;
  border: 1px solid #000;
  background-color: white;
  width: 300px;
  height: 60px;
  padding: 0.5em 1em;
  margin: 2em 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  box-shadow: rgba(233, 233, 233, 0.9) 3px 3px 0px 0px;
}
.cardbrand {
  font-size: 12px;
  margin: 8px 250px 0px 0;
}
.cardno {
  font-size: 12px;
  margin: -14px 100px 0px 0;
}
.cardexpire {
  font-size: 12px;
  margin: -17px -160px 0px 0;
}
.carddelete {
  font-size: 12px;
  color: red;
  margin: 25px -240px 0px 0;
  cursor: pointer;
}
.addpayment {
  color: rgb(255 255 255);
  font-size: 10px;
}
.returncontainer {
  margin: 100px 0 0 0;
}
.return {
  color: rgb(142 144 147);
  font-size: 12px;
}
.dialog {
  background-color: white;
  padding: 0.5em 1em;
  margin: 0 0;
  background: #fff;
  border: solid 1px;
  border-radius: 0 0 10px 10px; /*角の丸み*/
}

.dialogtitle {
  margin: 0 0 0 0;
  /* padding: 0.5em 1em; */
  background: #fff;
  border: solid 1px;
  border-radius: 10px 10px 0 0; /*角の丸み*/
  background-color: rgb(142 144 147);
}

.btn {
  background-color: rgb(226, 226, 226);
  box-shadow: rgba(233, 233, 233, 0.9) 3px 3px 0px 0px;
}
.addbobtn {
  background-color: rgb(237, 121, 149);
  box-shadow: rgba(233, 233, 233, 0.9) 3px 3px 0px 0px;
}
.defaultCard {
  font-size: 12px;
  margin-top: 5px;
  margin-left: 220px;
}
.setdefaultCard {
  font-size: 12px;
  margin-top: 5px;
  margin-left: 180px;
  cursor: pointer;
}
/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
@media only screen and (max-width: 1000px) {
  .login {
    color: rgb(255 255 255);
    font-size: 12px;
  }
}
</style>
