<script setup>
import { ref, onMounted } from "vue";
import { stripe } from "/src/stripe.js";
import { getFunctions, httpsCallable } from "firebase/functions";

const emit = defineEmits(["addcardclose"]);
const errortext = ref(null);
const error = ref(false);

const closeerrormessage = () => {
  error.value = false;
};

const elements = stripe.elements();

const elementStyles = {
  base: {
    color: "#32325D",
    fontWeight: 500,
    fontSize: "14px",
    fontSmoothing: "antialiased",
    "::placeholder": { color: "#CFD7DF" },
    ":-webkit-autofill": { color: "#e39f48" },
  },
  invalid: {
    color: "#E25950",
    "::placeholder": { color: "#FFCCA5" },
  },
};

const elementClasses = {
  focus: "focused",
  empty: "empty",
  invalid: "invalid",
};

const cardNumber = ref(null);
onMounted(() => {
  cardNumber.value = elements.create("cardNumber", {
    style: elementStyles,
    classes: elementClasses,
  });
  cardNumber.value.mount("#card-number");

  const cardExpiry = elements.create("cardExpiry", {
    style: elementStyles,
    classes: elementClasses,
  });
  cardExpiry.mount("#card-expiry");

  const cardCvc = elements.create("cardCvc", {
    style: elementStyles,
    classes: elementClasses,
  });
  cardCvc.mount("#card-cvc");
});

const addcardfun = async () => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";

  try {
    emit("addcardload");

    // サーバーでSetupIntentを作成
    const createSetupIntent = httpsCallable(functions, "Common_CreateSetupIntent");
    const { data: setupIntentData } = await createSetupIntent();

    const { clientSecret } = setupIntentData;

    // クライアント側でカードを保存
    const { error: stripeError, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardNumber.value,
      },
    });

    if (stripeError) {
      console.error("カード保存エラー:", stripeError.message);
      errortext.value = "カード保存に失敗しました: " + stripeError.message;
      error.value = true;
      emit("addcardloadoff");
      return;
    }

    console.log("カード保存成功:", setupIntent.payment_method);
    emit("addcardclose"); // カード追加成功後の処理
  } catch (err) {
    console.error("エラー:", err.message);
    errortext.value = "エラーが発生しました: " + err.message;
    error.value = true;
    emit("addcardloadoff");
  }
};

const addcardclose = () => {
  emit("addcardclose");
};
</script>

<template>
  <div class="body">
    <h3>クレジットカード情報の追加</h3>
    <div class="form">
      <div class="fields">
        <div class="field">
          <p class="title">カード番号</p>
          <div id="card-number" class="input inputcard" @click="closeerrormessage()"></div>
        </div>

        <div class="field">
          <p class="title">有効期限</p>
          <div id="card-expiry" class="input inputexpiry"></div>
        </div>

        <div class="field">
          <p class="title">セキュリティーコード</p>
          <div id="card-cvc" class="input inputcvc"></div>
        </div>
      </div>
    </div>
    <div class="unit">
      <p class="credittypetitle">以下のクレジットカードをご利用いただけます。</p>
      <img src="img/visa.png" alt="Visa" class="logopic" />
      <img src="img/mastercard.png" alt="MasterCard" class="logopic" />
      <img src="img/AXP.jpg" alt="American Express" class="logopic" />
      <img src="img/jcb.png" alt="JCB" class="logopic" />
    </div>

    <p class="errormessage" v-show="error">{{ errortext }}</p>

    <div class="btn_container">
      <button class="return" @click="addcardclose">キャンセル</button>
      <button class="title boxtitle" @click="addcardfun">カードを追加</button>
    </div>
  </div>
</template>

<style scoped>
.form {
  /* 子要素を水平方向の中央に配置する */
  display: flex;
  justify-content: center;
}
.fields {
  width: 300px;
  /* height: 50px; */
  /* background-color: red; */
}

.body {
  padding: 15px;
  font-size: 0.9rem;
  line-height: 1.5em;
  color: #333;
}

h3 {
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: #555;
  text-align: center;
}

.field {
  margin-bottom: 15px;
}

.field .title {
  font-size: 0.85rem;
  margin-bottom: 5px;
  color: black;
  text-align: left;
}

.input {
  font-size: 1rem;
  width: 100%;
  max-width: 300px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.inputcard {
  max-width: 400px;
}

.inputexpiry,
.inputcvc {
  max-width: 150px;
}

.unit {
  margin: 20px 0;
  text-align: center;
}

.credittypetitle {
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 10px;
}

.logopic {
  max-height: 30px;
  margin: 5px;
}

.errormessage {
  font-size: 0.8rem;
  color: red;
  margin-top: 10px;
}

.btn_container {
  text-align: center;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin: 5px;
}

button.return {
  background-color: #e2e2e2;
  color: #555;
  border: 1px solid #ccc;
}

button.return:hover {
  background-color: #ccc;
}

button.boxtitle {
  background-color: #da3990;
  color: white;
  border: 1px solid #da3990;
}

button.boxtitle:hover {
  background-color: #c02a78;
}
</style>
