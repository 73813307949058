<script setup>
import { toRefs } from "vue";

const props = defineProps({
  eventdatestart: String,
  eventdateend: String,
  price: String,
  shippingdate: String,
  livedatefirst: String,
  livedatesecond: String,
  livedatethird: String,
  livedatefourth: String,
  livedateend: String,
  totallivecount: Number,
});

const {
  eventdatestart,
  eventdateend,
  price,
  shippingdate,
  livedatefirst,
  livedatesecond,
  livedatethird,
  livedatefourth,
  livedateend,
} = toRefs(props);
</script>

<template>
  <v-container class="pa-0 mt-5">
    <v-row no-gutters>
      <v-col cols="12" md="8" offset-md="2">
        <div class="box d-flex flex-column">
          <v-row class="align-center">
            <v-col class="d-flex align-center pr-1" cols="1"
              ><img class="icon" src="img/time.png"
            /></v-col>
            <v-col class="d-flex align-center pl-1" cols="10">
              <p><span class="subtitle">イベント開始: </span>{{ eventdatestart }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center pl-1 offset-1" cols="10">
              <p><span class="subtitle">イベント終了: </span>{{ eventdateend }}</p>
            </v-col>
          </v-row>

          <v-row class="align-center mt-2">
            <v-col class="d-flex align-center pr-1" cols="1"
              ><img class="icon" src="img/price.png"
            /></v-col>
            <v-col class="d-flex align-center pl-1" cols="10">
              <p><span class="subtitle">グッズ販売価格:</span>１回{{ price }}円（税込）</p>
            </v-col>
          </v-row>
          <v-row class="align-center mt-2">
            <v-col class="d-flex align-center pr-1" cols="1"
              ><img class="icon" src="img/shipping.png"
            /></v-col>
            <v-col class="d-flex align-center pl-1" cols="10">
              <p><span class="subtitle">送料: </span>550円（税込・20回毎にかかります）</p>
            </v-col>
          </v-row>
          <v-row class="align-center mt-2">
            <v-col class="d-flex align-center pr-1" cols="1"
              ><img class="icon" src="img/otodoke.png"
            /></v-col>
            <v-col class="d-flex align-center pl-1" cols="10">
              <p><span class="subtitle">お届け目安: </span>{{ shippingdate }}</p>
            </v-col>
          </v-row>
          <v-row class="align-center mt-2">
            <v-col class="d-flex align-center pr-1" cols="1"
              ><img class="icon" src="img/otodoke.png"
            /></v-col>
            <v-col class="d-flex align-center pl-1" cols="10">
              <p><span class="subtitle">初回配信日時: </span>{{ livedatefirst }}</p>
            </v-col>
          </v-row>
          <v-row v-if="livedatesecond">
            <v-col class="d-flex align-center pl-1 offset-1" cols="10">
              <p><span class="subtitle">2回目配信日時: </span>{{ livedatesecond }}</p>
            </v-col>
          </v-row>
          <v-row v-if="livedatethird">
            <v-col class="d-flex align-center pl-1 offset-1" cols="10">
              <p><span class="subtitle">3回目配信日時: </span>{{ livedatethird }}</p>
            </v-col>
          </v-row>
          <v-row v-if="livedatefourth">
            <v-col class="d-flex align-center pl-1 offset-1" cols="10">
              <p><span class="subtitle">4回目配信日時: </span>{{ livedatefourth }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center pl-1 offset-1" cols="10">
              <p><span class="subtitle">最終回配信日時: </span>{{ livedateend }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center pl-1 offset-1" cols="10">
              <p><span class="subtitle">※配信回数: </span>{{ totallivecount }}回</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center pl-1 offset-1 mt-5" cols="10">
              <p>※イベント開始は初回配信開始時日時、イベント終了は最終回配信終了日時となります</p>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.box {
  border: 1px solid rgb(226, 226, 226);
  background-color: white;
  padding: 16px;
  text-align: left;
}
.icon {
  width: 20px;
  margin-left: 10px;
}
.subtitle {
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  .box {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 12px;
  }
  .icon {
    width: 16px; /* アイコンサイズを小さくする */
    margin-left: 0px;
  }
}
</style>
